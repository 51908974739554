body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td {
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  width: 10px;
}
/* td:hover {
  overflow:visible;
  border: 1px solid #eaebec;
  padding: 0 .5rem;
  max-width: 20rem;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
  white-space: normal;
  word-wrap: break-word;
  color:black;
  margin-top:-1.25rem;
} */

table {
  table-layout: 'fixed'
}
