.ripple-badge {
  /* margin-top: -2px; */
  position: relative;
  display: inline-block;
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
}

.badge {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 255, 94); /* Choose your badge color */
  color: white;
  border-radius: 50%;
  z-index: 1;
}

.ripple {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 255, 47, 0.7); /* Red color for the ripple effect */
  transform: scale(0);
  animation: ripple-animation 1s linear infinite;
}

@keyframes ripple-animation {
  to {
    transform: scale(2);
    opacity: 0;
  }
}
