.section_our_solution .row {
    display: flex;
    align-items: center;
  }
  
  .solution_card {
    flex: 0 50%;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
      0 5px 15px 0 rgba(37, 44, 97, 0.15);
    border-radius: 15px;
    margin: 8px;
    padding: 10px 15px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-height: 180px;
    transition: 0.7s;
    margin: 0em 0em 1.5em 1.5em;
  }
  
  .solution_card:hover {
    background: #309df0;
    color: #fff;
    transform: scale(1.1);
    z-index: 9;
  }
  
  .solution_card:hover::before {
    background: rgb(85 108 214 / 10%);
  }
  
  .solution_card:hover .solu_title h3,
  .solution_card:hover .solu_description p {
    color: #fff !important;
  }
  
  .solution_card:hover .solu_description button {
    background: #fff !important;
    color: #309df0;
  }
  
  .solution_card:before {
    content: "";
    position: absolute;
    background: rgb(85 108 214 / 5%);
    width: 200px;
    height: 400px;
    z-index: -1;
    transform: rotate(42deg);
    right: -56px;
    top: -23px;
    border-radius: 35px;
  }
  
  .solution_card:hover .solu_description button {
    background: #fff !important;
    color: #309df0;
  }
  
  .solution_card .solu_title h3 {
    color: #212121;
    font-size: 1.5rem;
    margin-top: 13px;
    margin-bottom: 13px;
  }
  .solution_card:hover::before {
    background: rgb(85 108 214 / 10%);
  }
  
  .solution_card .solu_description p {
    font-size: 15px;
    margin-bottom: 15px;
  }
  
  .solution_card:hover .solu_description .link {
    background: #fff !important;
    color: #309df0;
  }
  
  .solution_card .solu_description button {
    border: 0;
    border-radius: 15px;
    background: linear-gradient(
      140deg,
      #42c3ca 0%,
      #42c3ca 50%,
      #42c3cac7 75%
    ) !important;
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    padding: 5px 16px;
  }
  
  .our_solution_content h1 {
    text-transform: capitalize;
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  
  /*start media query*/
  @media screen and (min-width: 320px) {
    .sol_card_top_3 {
      position: relative;
      top: 0;
    }
  
    .our_solution_category {
      width: 100%;
      margin: 0 auto;
    }
  
    .our_solution_category {
      flex: auto;
    }
  }
  @media only screen and (min-width: 768px) {
    .our_solution_category {
      flex: 1;
    }
  }
  @media only screen and (min-width: 1024px) {
    .sol_card_top_3 {
      position: relative;
      top: -3rem;
    }
    .our_solution_category {
      width: 50%;
      margin: 0 auto;
    }
  }
  
  .hover_color_bubble {
    position: absolute;
    background: rgb(54 81 207 / 15%);
    width: 100rem;
    height: 100rem;
    left: 0;
    right: 0;
    z-index: -1;
    top: 16rem;
    border-radius: 50%;
    transform: rotate(-36deg);
    left: -18rem;
    transition: 0.7s;
  }
  
  .solution_card:hover .hover_color_bubble {
    top: 0rem;
  }
  
  .solution_card:hover .solu_description .link {
    background: #fff !important;
    color: #309df0;
  }
  
  .read_more_btn{
    margin-top: 1em;
    padding: 100px;
  }
  
  .link{
    color: #fff;
  }